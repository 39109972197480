import React from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as style from 'styles/components/player/item.module.scss';
import CustomLink from 'components/utils/CustomLink';
import FormattedMessage from 'components/utils/FormattedMessage';

import defaultImage from '../../images/hif_default.png';

const PlayerItem = ({ player }) => {

    let playerPosition  =  player.position ? player.position : 'undefined';

    if( playerPosition === 'management' ) {
        playerPosition = player.detailedPosition ? player.detailedPosition : 'undefined';
    }

    return (
        <div className={style.playerCard__container}>
            <CustomLink to={player.slug.current} className={style.playerCard__wrapper}>
                <div className={style.playerCard__overlay} />

                {player.image && player.image.length > 0 ? (
                    <div className={style.playerCard__image}>
                        <ImageGatsby
                            {...player.image[0].image}
                            height={800}
                            alt={`${player.firstName} ${player.lastName}`}
                            title={`${player.firstName} ${player.lastName}`}
                        />
                    </div>
                ) : (
                    <div className={style.playerCard__image}>
                        <img
                            src={defaultImage}
                            height={800}
                            alt={`${player.firstName} ${player.lastName}`}
                            title={`${player.firstName} ${player.lastName}`}
                        />
                    </div>
                )}

                <div className={style.playerCard__content}>
                    <span className={style.playerCard__number}>{player.number}</span>
                    <div className={style.playerCard__infoWrapper}>
                        <span className={style.playerCard__position}><FormattedMessage id={`player.position-${playerPosition}-singular`} /></span>
                        <h3 className={style.playerCard__name}>{`${player.firstName} ${player.lastName}`}</h3>
                    </div>
                </div>
            </CustomLink>
        </div>
    );
};

export default PlayerItem;
